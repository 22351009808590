import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/reusables/Card';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import constants from 'appConstants';
import { noop } from 'lodash-es';
import TextField from '@beewise/text-field';
import { clearBeehomeFileToDownload } from '../../actions';
import { getFileForDownload, getLogsError } from '../../selectors';

const BeehomeLogs = ({ bhome }) => {
    const dispatch = useDispatch();
    const [absPath, setAbsPath] = useState('');
    const [s3Path, setS3Path] = useState('');

    const logsError = useSelector(getLogsError);
    const fileToDownload = useSelector(getFileForDownload);

    useEffect(() => {
        if (!fileToDownload) {
            return;
        }

        const a = document.createElement('a');
        a.href = fileToDownload;
        a.click();
        dispatch(clearBeehomeFileToDownload());
    }, [dispatch, fileToDownload]);

    const handleDownloadLogs = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(
                bhome.id,
                {
                    type: constants.COMMANDS.GET_LOGS,
                    params: {},
                },
                noop,
                'Command sent. Please wait on this page for the download to start.'
            )
        );
    }, [bhome.id, dispatch]);

    const handleDownloadFile = useCallback(() => {
        if (!absPath) {
            return;
        }

        dispatch(
            fetchSendTechnicianAction(
                bhome.id,
                {
                    type: constants.COMMANDS.GET_FILE_FROM_BEEHOME,
                    params: {
                        abs_path: absPath,
                        ...(s3Path ? { s3_path: s3Path } : {}),
                    },
                },
                noop,
                'Command sent. Please wait on this page for the download to start.'
            )
        );
    }, [absPath, bhome.id, dispatch, s3Path]);

    return (
        <div className="beehome-logs-center">
            <Card className="bhome-logs" title="Beehome Files">
                <div className="logs-section flex-line flex-line-left">
                    <TechButton className="download-logs btn-primary" onClick={handleDownloadLogs} alwaysEnabled>
                        Download logs
                    </TechButton>
                </div>
                <div className="logs-section flex-line flex-line-left download-file">
                    <TextField label="Abs Path" value={absPath} onChange={setAbsPath} size="small" />
                    <TextField label="S3 Path (optional)" value={s3Path} onChange={setS3Path} size="small" />
                    <TechButton
                        className="download-logs btn-primary"
                        onClick={handleDownloadFile}
                        enabledInAnyState
                        disabled={!absPath}
                    >
                        Download File
                    </TechButton>
                </div>
                {logsError && <div className="bhome-logs-error">{logsError}</div>}
            </Card>
        </div>
    );
};

BeehomeLogs.propTypes = {
    bhome: PropTypes.shape(),
};

export default BeehomeLogs;
