import { createSelector } from 'reselect';
import { isLimitedTestUser, isInLimitedBhomeBayRange, LIMITED_MANUFACTURING_BHOME_LIST, isProduction } from 'utils';

export const getBhomes = state => state.app.bhomes;
export const getFilteredProdBhomes = state =>
    isProduction ? state.app.bhomes.filter(item => item.id > 10000) : state.app.bhomes;
export const getUser = state => state.app.user;

export const showOnlyOnline = (state, showOnline) => showOnline;
export const bhomesConnectivityState = (state, showOnline, bhomesConnectivityState) => bhomesConnectivityState;
export const bhomesToFilter = (state, showOnline, bhomesConnectivityState, bhomesToFilter) => bhomesToFilter;

export const getSortedBhomeIdsOptions = createSelector(getBhomes, getUser, (bhomes, user) => {
    // limit mexico FAT users to certain bhome ID range
    if (isLimitedTestUser(user)) {
        return bhomes.reduce((acc, bhome) => {
            if (isInLimitedBhomeBayRange(bhome?.id) || LIMITED_MANUFACTURING_BHOME_LIST.includes(bhome?.id)) {
                acc.push({ label: `Beehome ID: ${bhome.id}`, value: bhome.id });
            }
            return acc;
        }, []);
    }

    return bhomes.map(bhome => ({ label: `Beehome ID: ${bhome.id}`, value: bhome.id }));
});

export const getBhomesWithIpCamera = createSelector(
    getBhomes,
    showOnlyOnline,
    bhomesConnectivityState,
    bhomesToFilter,
    (bhomes, showOnlyOnline, bhomesConnectivityState, bhomesToFilter) =>
        bhomes.filter(bhome => {
            const isIpCamera =
                bhome?.settings?.machine_specific?.ui_settings?.isIpCamera ||
                bhome?.settings?.defaults?.ui_settings?.isIpCamera;

            if (showOnlyOnline) {
                if (!bhomesToFilter.length) {
                    return (
                        isIpCamera && !!bhomesConnectivityState.find(item => item.id === bhome.id && item.connected)?.id
                    );
                }
                const connectivityState = bhomesConnectivityState.find(item => item.id === bhome.id && item.connected);
                return isIpCamera && !!connectivityState?.id && bhomesToFilter.some(id => id === connectivityState.id);
            }

            if (bhomesToFilter?.length) {
                return isIpCamera && bhomesToFilter.some(id => id === bhome.id);
            }

            return isIpCamera;
        })
);
