import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import Card from 'components/reusables/Card';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import { clearDevices, fetchCameraDevices } from '../../actions';
import { getDevices, getIpCameraDevice } from '../../selectors';

import './Devices.scss';

const Devices = ({ bhome, isNavCalibrationInProgress }) => {
    const dispatch = useDispatch();

    const devices = useSelector(getDevices);
    const ipCameraDevice = useSelector(getIpCameraDevice);

    const handleFetchCameraDevices = useCallback(() => {
        dispatch(clearDevices());
        dispatch(fetchCameraDevices(bhome.id));
    }, [bhome.id, dispatch]);

    const handleGetSysInfo = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(bhome.id, {
                type: constants.COMMANDS.GET_SYS_INFO,
            })
        );
    }, [bhome.id, dispatch]);

    const hasSimInfo = useMemo(
        () => bhome?.sys_info?.modem?.sim_card_data?.iccid && bhome?.sys_info?.modem?.sim_card_data?.serial,
        [bhome]
    );

    const simCardData = useMemo(() => {
        if (!bhome?.sys_info?.modem?.sim_card_data) {
            return [];
        }
        return Object.keys(bhome.sys_info.modem.sim_card_data).map(key => ({
            key: key.replaceAll('_', ' '),
            value: bhome.sys_info.modem.sim_card_data[key],
        }));
    }, [bhome?.sys_info?.modem?.sim_card_data]);

    return (
        <div className="devices-center">
            <Card className="devices" title="Devices">
                <div className="devices-side">
                    <TechButton
                        alwaysEnabled={!isNavCalibrationInProgress}
                        className="btn-primary"
                        onClick={handleFetchCameraDevices}
                        showToManufacturer
                        disabled={isNavCalibrationInProgress}
                    >
                        Fetch devices
                    </TechButton>
                    <div className="devices-content">
                        {devices.map(device => (
                            <div className="device" key={device.device}>
                                Device: {device.device}, Alive: ${device.alive ? 'yes' : 'no'}
                            </div>
                        ))}
                        {ipCameraDevice && (
                            <div className="device">
                                Device: IP Camera, Alive: {ipCameraDevice.alive ? 'yes' : 'no'}
                            </div>
                        )}
                    </div>
                </div>
                <div className="devices-side">
                    <div>
                        <TechButton
                            alwaysEnabled={!isNavCalibrationInProgress}
                            disabled={isNavCalibrationInProgress}
                            className="btn-primary"
                            onClick={handleGetSysInfo}
                            showToManufacturer
                        >
                            {hasSimInfo ? 'Refresh' : 'Fetch'} SIM Info
                        </TechButton>
                        <span className="bold small">
                            Last Updated:{' '}
                            {bhome?.sys_info?.modemTimestamp
                                ? new Date(bhome?.sys_info?.modemTimestamp).toLocaleString()
                                : 'never'}
                        </span>
                    </div>
                    <div className="sim-content">
                        {simCardData.map(({ key, value }) => (
                            <div key={key}>
                                <span className="bold">{key}:</span> {value}
                            </div>
                        ))}
                    </div>
                </div>
            </Card>
        </div>
    );
};

Devices.propTypes = {
    bhome: PropTypes.shape(),
    isNavCalibrationInProgress: PropTypes.bool,
};

export default Devices;
